/**
 * Global variables
 */
:export {
  stutter: 50; }

.slideUp, .slideUpFull, .slideDown, .slideLeft, .slideLeftFull, .slideRight, .fadeIn, .growIn {
  opacity: 0;
  animation-duration: 800ms;
  animation-fill-mode: forwards; }
  :global(.no-cssanimations) .slideUp, :global(.no-cssanimations) .slideUpFull, :global(.no-cssanimations) .slideDown, :global(.no-cssanimations) .slideLeft, :global(.no-cssanimations) .slideLeftFull, :global(.no-cssanimations) .slideRight, :global(.no-cssanimations) .fadeIn, :global(.no-cssanimations) .growIn {
    opacity: 1; }

.slideDownOut, .slideRightOut, .fadeOut {
  animation-duration: 800ms;
  animation-fill-mode: forwards; }

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(1rem); }
  100% {
    opacity: 1; } }

.slideUp {
  animation-name: slideUp; }

@keyframes slideUpFull {
  0% {
    opacity: 0;
    transform: translateY(100%); }
  100% {
    opacity: 1; } }

.slideUpFull {
  animation-name: slideUpFull; }

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-1rem); }
  100% {
    opacity: 1; } }

.slideDown {
  animation-name: slideDown; }

@keyframes slideDownOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translateY(4rem); } }

.slideDownOut {
  animation-name: slideDownOut;
  animation-duration: 400ms; }

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(1rem); }
  100% {
    opacity: 1; } }

.slideLeft {
  animation-name: slideLeft; }

@keyframes slideLeftFull {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1; } }

.slideLeftFull {
  animation-name: slideLeftFull;
  animation-timing-function: cubic-bezier(0.1, 1, 0.55, 1);
  animation-duration: 400ms; }

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-1rem); }
  100% {
    opacity: 1; } }

.slideRight {
  animation-name: slideRight; }

@keyframes slideRightOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translateX(4rem); } }

.slideRightOut {
  animation-name: slideRightOut; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut; }

@keyframes growIn {
  0% {
    opacity: 0;
    transform: scale(0); }
  100% {
    opacity: 1; } }

.growIn {
  animation-name: growIn; }

.short {
  animation-duration: 200ms; }

.slow {
  animation-duration: 800ms; }

.paused {
  animation-play-state: paused; }
