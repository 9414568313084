/**
 * Global variables
 */
.block {
  padding-top: 1rem; }

.label {
  display: flex;
  align-items: center;
  cursor: text;
  transition: transform 200ms;
  user-select: none;
  pointer-events: none;
  position: absolute;
  top: 1rem;
  right: 0;
  bottom: 0;
  left: 0; }
  .input:focus + .label,
  .input.hasValue + .label {
    transform: translateY(-2rem); }
    :global(.no-csstransforms) .input:focus + .label, :global(.no-csstransforms)
    .input.hasValue + .label {
      margin-top: -2rem;
      top: 0; }

.message {
  display: inline-block;
  margin-top: 0.5rem;
  font-size: 0.75em;
  line-height: 1.15;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0; }

.error {
  color: #f44336; }
