/**
 * helpers.scss
 */
/**
 * Global variables
 */
.cf:after {
  content: "";
  clear: both;
  display: table; }

.flat {
  margin: 0 !important; }

.text--bold {
  font-weight: 600; }

.text--lead {
  font-size: 1.125em; }

.svg--white {
  fill: white; }

.svg--light {
  fill: #f4f5f8; }

@media (min-width: 1374px) {
  .stretch {
    left: -80px;
    width: calc(100% + 160px);
    max-width: none; }
    :global(.no-csscalc) .stretch {
      left: auto; } }

@media (max-width: 379px) {
  .hide-below--mini {
    display: none !important; } }

@media (max-width: 449px) {
  .hide-below--tiny {
    display: none !important; } }

@media (max-width: 599px) {
  .hide-below--small {
    display: none !important; } }

@media (max-width: 857px) {
  .hide-below--medium {
    display: none !important; } }

@media (max-width: 1213px) {
  .hide-below--large {
    display: none !important; } }

@media (min-width: 380px) {
  .hide-above--mini {
    display: none !important; } }

@media (min-width: 450px) {
  .hide-above--tiny {
    display: none !important; } }

@media (min-width: 600px) {
  .hide-above--small {
    display: none !important; } }

@media (min-width: 858px) {
  .hide-above--medium {
    display: none !important; } }

@media (min-width: 1214px) {
  .hide-above--large {
    display: none !important; } }

.line {
  display: inline-block;
  margin-right: 1.5rem !important;
  margin-bottom: 1.5rem !important; }
